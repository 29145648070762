/** @jsx jsx */
import React from "react"
import {
  Box,
  Grid,
  Label,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  Flex,
  jsx,
} from "theme-ui"
import PageHeader from "../components/PageHeader"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

// icons
import Logo from "../../images/logo2.svg"
import CheckedIcon from "../../images/checked.svg"
import CheckedDisabledIcon from "../../images/checkedDisabled.svg"
import ClassroomIcon from "../../images/classroom.svg"
import InterviewIcon from "../../images/interview.svg"
import OnlineClassIcon from "../../images/onlineCourse.svg"
import ConfusedIcon from "../../images/confused.svg"

interface SpecialityProps {
  text: string
}

const Speciality = (props: SpecialityProps) => {
  const { text } = props
  return (
    <Flex mb={2} sx={{ alignItems: "center" }}>
      <img sx={{ height: "1.25rem" }} src={CheckedIcon} alt="checked" />
      <Text variant="secondary" sx={{ fontSize: [0,3] }} ml={2}>
        {text}
      </Text>
    </Flex>
  )
}

const Specialities = (): JSX.Element => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img height="75" src={Logo} alt="logo" />
      <Box mt={4} sx={{ textAlign: "center" }}>
        <Box
          sx={{
            display: "inline-block",
          }}
        >
          <Speciality text="10+ Experienced Faculties" />
          <Speciality text="Free Guest Webinars" />
          <Speciality text="Ranked Online Test series" />
          <Speciality text="Current Affairs Magazine" />
          <Speciality text="Excellent Course Materials" />
        </Box>
      </Box>
    </Box>
  )
}

interface CourseButtonProps {
  icon: string
  value: string
}

const CourseButton = (props: CourseButtonProps): JSX.Element => {
  const { icon, value } = props
  return (
    <span sx={{ display: "inline-block" }}>
      <input
        type="radio"
        id={value}
        value={value}
        name="course"
        sx={{ display: "none !important" }}
      />
      <Label
        htmlFor={value}
        p={3}
        m={3}
        sx={{
          width: ["135px", "170px"],
          height: ["140px", "150px"],
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1em",
          border: "1px solid #D5D5D5",
          textAlign: "center",
          position: "relative",
          fontSize:[0,1],
          "input:checked + &": {
            border: "1px solid #58D996",
            bg: "#5CDD990D",
          },
          "input:checked + & .notCheckedImage": {
            display: "none",
          },
          "input:not(:checked) + & .notCheckedImage": {
            display: "inline-block",
          },
          "input:checked + & .checkedImage": {
            display: "inline-block",
          },
          "input:not(:checked) + & .checkedImage": {
            display: "none",
          },
        }}
      >
        <img
          src={CheckedDisabledIcon}
          className="notCheckedImage"
          sx={{
            position: "absolute",
            top: "1em",
            right: "1em",
          }}
          alt="checked icon"
        />
        <img
          src={CheckedIcon}
          className="checkedImage"
          sx={{
            position: "absolute",
            top: "1em",
            right: "1em",
          }}
          alt="checked icon"
        />
        <img
          sx={{
            maxHeight: "70px",
          }}
          src={icon}
          alt={value}
        />
        <Text mt={2} color="#605F5F">
          {value}
        </Text>
      </Label>
    </span>
  )
}

interface InputLabelProps {
  htmlFor: string
  children: React.ReactNode
}

const InputLabel = (props: InputLabelProps) => {
  const { htmlFor, children } = props
  return (
    <Label my={3} sx={{ color: "text", fontSize: [1,3] }} htmlFor={htmlFor}>
      {children}
    </Label>
  )
}

const Enroll = (): JSX.Element => {
  return (
    <Box>
      <SEO title="Enroll Now" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [2, 5], fontWeight: "bold", textAlign: "center" }}
          >
            Join Sarkar
          </Text>
          <Text
            sx={{ fontSize: [2, 4], fontWeight: "bold", textAlign: "center" }}
          >
            Best TNPSC, UPSC Coaching in India
          </Text>
        </PageHeader>
        <Heading
          mt={[2, 4]}
          mb={[2, 5]}
          sx={{ fontSize: [2, 5], textAlign: "center" }}
        >
          Please provide us your details
        </Heading>
        <Grid columns={[1, 1, 1, "2fr 3fr"]} gap={3}>
          <Box
            sx={{
              order: [1, 1, 1, 0],
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: "150px",
              }}
              pb={[0, 6]}
            >
              <Specialities />
            </Box>
          </Box>
          <Box px={3}>
            <form
              id="enrollForm"
              name="enroll"
              method="post"
              data-netlify="true"
              action="/thankYou/enroll"
            >
              <Box>
                <InputLabel htmlFor="course">
                  1. What would you like to enroll?
                </InputLabel>
                <Flex sx={{ flexWrap: "wrap" }}>
                  <CourseButton icon={ClassroomIcon} value="Classroom Course" />
                  <CourseButton
                    icon={InterviewIcon}
                    value="Free Interview Training"
                  />
                  <CourseButton icon={OnlineClassIcon} value="Online Course" />
                  <CourseButton icon={ConfusedIcon} value="General Enquiry" />
                </Flex>
              </Box>
              <Box sx={{ maxWidth: "400px" }}>
                <InputLabel htmlFor="name">2. Your Name?</InputLabel>
                <Input type="text" name="name" id="name" required />
              </Box>
              <Box sx={{ maxWidth: "400px" }} mt={2}>
                <InputLabel htmlFor="mobile">
                  3. Your Contact Number?
                </InputLabel>
                <Input type="text" name="mobile" id="mobile" required />
              </Box>
              <Box sx={{ maxWidth: "400px" }} mt={2}>
                <InputLabel htmlFor="mobile">
                  4. Your Alternative Contact Number?
                </InputLabel>
                <Input type="text" name="mobile" id="mobile" required />
              </Box>
              <Box sx={{ maxWidth: "400px" }} mt={2}>
                <InputLabel htmlFor="email">5. Your Email Id:</InputLabel>
                <Input type="email" name="email" id="email" required />
              </Box>
              <Box sx={{ maxWidth: "400px" }} mt={2}>
                <InputLabel htmlFor="questions">
                  6. Any Specific Questions Please Text Us:
                </InputLabel>
                <Textarea name="questions" id="questions" />
              </Box>
              <Box mt={4} mb={6}>
                <Button
                  sx={{
                    borderRadius: "0.4em",
                    backgroundImage: "linear-gradient(#40EA9A, #04D072)",
                  }}
                  px={5}
                >
                  <Text sx={{ fontSize: [0, 3] }} variant="caps">
                    Submit
                  </Text>
                </Button>
              </Box>
              <input type="hidden" name="form-name" value="enroll" />
            </form>
          </Box>
        </Grid>
      </Layout>
    </Box>
  )
}

export default Enroll
